import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import NotFoundError from "../rebrand/pages/NotFoundPage";
import ErrorPage from "../rebrand/pages/Error";

function handleOnClick(): void {
  window?.location.reload();
}

export default function NextErrorPage({ statusCode }: { statusCode: number }) {
  if (statusCode === 404) {
    return <NotFoundError />;
  }
  if (statusCode === 500) {
    return (
      <ErrorPage
        title="Our bad..."
        description="Somewhere, something just went wrong. Click below to refresh the page. If that doesn’t work, tell us directly or email support@oliva.health."
        onClickButton={handleOnClick}
        buttonLabel="Refresh the page"
      />
    );
  }
}

export async function getServerSideProps({ locale, res }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      statusCode: res.statusCode,
    },
  };
}
